import React from "react"
import { Helmet } from "react-helmet";
import Banner from "../components/Banner/Banner";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Layout from "../components/Layout/layout"
import Features from "../Views/Website/Features/Features";

const FeaturesPage: React.FC = (props: any) => {
    return (
        <Layout>
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>PrimeHR :: Features</title><meta charSet='utf-8' />
                <meta property="og:description" content={``} />
                <meta property="og:image" content={``} />
                <meta property="og:title" content={`Features`} />
                <meta property="og:site_name" content="PrimeHR" />
                <meta property="og:url" content={`https://primehr.co.uk/${props.location.pathname}`} />
            </Helmet>

            <Banner />
            <Breadcrumb Trail={[
                { To: "", Text: "Features" }
            ]} />

            <Features />
        </Layout>
    );
};

export default FeaturesPage;