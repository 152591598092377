import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as styles from './Features.module.scss';
import { faDownload, faFileContract, faFileSignature, faFileWord, faFileExcel, faFolderOpen, faUserFriends, faUserTie, faBuilding, faUserClock, faUserCheck, faUserShield, faIdCard, faFileExport, faFileInvoice, faBusinessTime, faPlane } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'gatsby';
import templates from '../../../assets/IMG/Features/templates.jpg';
import storage from '../../../assets/IMG/Features/storage.jpg';
import employees from '../../../assets/IMG/Features/employees.jpg';
import portal from '../../../assets/IMG/Features/portal.jpg';

type feature = {
  Title: string;
  Description: any;
  Icon: IconProp;
  Image: string;
  SplitIcons: IconProp[];
}

const Features: React.FC = () => {
  let FeatureList: feature[] = [{
    Title: "Template Documents",
    Description: <>
      PrimeHR's original feature has only got better and better over the years. With more documents than ever before, with increased updates and reviews from our HR experts.
      <br />
      <br />
      Our latest Documents portal now includes the ability to add your own values into variables across various templates. Making each download unique as well as standardised throughout your business.
      <br />
      <br />
      To add to the customisation of each document, your company Logo is added by default to the top of the file making each download branding for your business. Or if you are just needing the document content then you can download the file as a basic editable format.
      <br />
      <br />
      Check out our <Link to={`/Documents`}>Documents</Link> to download our FREE files and find out which Document Package is right for you.
    </>,
    Icon: faDownload,
    Image: templates,
    SplitIcons: [faFileContract, faFileSignature, faFileInvoice]
  }, {
    Title: "Secure Document Storage",
    Description: <>
      Need a place to store a backup of all your downloaded policies, created contracts and business critical documents?
      <br />
      <br />
      Our document storage system comes standard as part of our FREE Bronze package. Ensuring that a small Business owner can keep their costs low and all their business information is stored in one easy to access location.
      <br />
      <br />
      <h3>Are you a PreciseHR Customer?</h3>
      As part of <a href={`https://precisehr.co.uk/`}>PreciseHR</a>'s SmartHub offering, we have created a Secure Document Storage as part of our HR Platform. This has all the features of the PreciseHR SmartHub to ensure ease of use and familiar feel when moving over to PrimeHR.
      <br />
      <br />
      Already got a whole bunch of documents in the PreciseHR SmartHub? No worries. Use our easy to ease importer to pull your documents from the SmartHub over to PrimeHR and continue your standard operating without any issues!
    </>,
    Icon: faFolderOpen,
    Image: storage,
    SplitIcons: [faFileWord, faFileExcel, faFileExport]
  }, {
    Title: "Employee Management",
    Description: <>
      Employee Management is a key part of any business and an integral component in any HR journey. Our Employee Management system is designed for small businesses to be able to learn and develop their HR strategy as they work through employing their first employee or their 50th.
      <br />
      <br />
      As we find and plan new features for our system, you will get automatic updates and access to latest features to support your HR journey.
      Some of our latest features include:
      <br />
      <br />
      <ul>
        <li>Flexible Working Manager</li>
        <li>Policy Management</li>
        <li>Payroll Journal</li>
        <li>Absense Management</li>
        <li>Employee Information</li>
      </ul>
      <br />
      <br />
      And more new features still to come as we develop and create more useful tools to help your business thrive and grow. <Link to={`/Register`}>Sign up</Link> now to starts using our FREE for Bronze Employee Management System.
    </>,
    Icon: faUserFriends,
    Image: employees,
    SplitIcons: [faUserCheck, faUserShield, faBusinessTime]
  }, {
    Title: "Employee Portal",
    Description: <>
      We know the struggles of keeping track of your employees during the workday in the modern age, especially with changes in remote working. In response to these growing changes, we have created an Employee Portal that allows your employee to manage their day-to-day.
      <br />
      <br />
      Our Employee Portal allows your employees to clock-in and out for the day, request holidays and sign the company policies. From a manager and team leader point of view, you can also see your teams or departments absenses to plan ahead for the future.
      <br />
      <br />
      Our <Link to={`/Bronze`}>Bronze package</Link> gives you 5 FREE Employees to help you start your HR Journey. Try it now and get your first employees signed up!
    </>,
    Icon: faUserTie,
    Image: portal,
    SplitIcons: [faUserClock, faIdCard, faPlane]
  }]

  return (
    <>
      <div className={styles.Section}>
        <h1>Features</h1>
        <p>Our online Portal platform provides more than just documents, thanks to all the expertise and experience we have gathered over the years, we have been able to build a unique HR Management Experience. Design for small businesses who either are just starting off on their HR adventure or who are looking for a modern system that ticks all the boxes.</p>

        {
          FeatureList.map((feature: feature) => {
            return <div className={styles.SectionInner}>
              <div className={styles.SectionImageInfo}>
                <div className={styles.SectionBlock}>
                  <h2>{feature.Title}</h2>
                  <pre>
                    {feature.Description}
                  </pre>
                </div>
              </div>

              <div className={styles.SectionImage} style={{ backgroundImage: `url(${feature.Image})` }}>
                <div className={styles.SectionImageItem}>
                  <i>
                    <FontAwesomeIcon icon={feature.Icon} />
                  </i>
                  <div className={styles.SectionImageIcons}>
                    {
                      feature.SplitIcons.map((icon) => {
                        return <i>
                          <FontAwesomeIcon icon={icon} />
                        </i>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
    </>
  );
};

export default Features;
